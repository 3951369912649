<template>
  <el-main slot="el-main">
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="1"></el-tab-pane>
        <el-tab-pane label="商品规格" name="2"></el-tab-pane>
        <el-tab-pane label="商品详情" name="3"></el-tab-pane>
        <el-tab-pane label="营销活动" name="4"></el-tab-pane>
      </el-tabs>
    </div>
    <el-form class="form" ref="form" :model="goodsForm" style="width: 100%" label-width="160px" :rules="rules">
      <div v-show="activeName == 1">
        <div class="basic-info">
          <div class="title">基本信息</div>
          <div class="content">
            <el-form-item v-if="is_edit" label="商品分类：" class="item goods-type" prop="classify_ids">
              <el-cascader v-model="goodsForm.classify_ids" placeholder="请选择或搜索" clearable filterable
                :props="{ multiple: true }" :options="goodsClass" @change="handleClassChange"></el-cascader>
              <router-link to="/goods/AddType" style="margin-left: 15px">
                <el-button type="text">没有分类，去添加分类</el-button>
              </router-link>
            </el-form-item>
            <el-form-item v-if="is_edit" label="排序：" class="item">
              <el-input type="number" :min="0" v-model="goodsForm.sort" placeholder="请输入内容"></el-input>
              <span>数字越大，排名越靠前；如为空，排序默认认为添加时间越近越靠前</span>
            </el-form-item>
            <el-form-item label="商品名称：" class="item" prop="goods_name">
              <el-input v-model="goodsForm.goods_name" type="textarea" autosize placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="商品副标题：" class="item">
              <el-input v-model="goodsForm.goods_subtitle" type="textarea" size="medium" autosize placeholder="请输入内容">
              </el-input>
              <span>在商品详情页商品标题下显示，建议120符号/60字以内</span>
            </el-form-item>
            <el-form-item label="搜索关键字：" class="item">
              <el-input v-model="goodsForm.goods_tag" placeholder="请输入内容"></el-input>
              <span>搜索框中输入此关键字，则可在搜索列表中显示此商品，关键字间隔用“,”隔开</span>
            </el-form-item>
            <el-form-item label="虚拟出售数量：" class="item">
              <el-input type="number" :min="0" v-model="goodsForm.virtual_sales" placeholder="请输入内容"></el-input>
              <span>前端显示=虚拟出售数量+实际销售数量</span>
            </el-form-item>
            <el-form-item label="商品图片：" class="item" prop="goods_picture">
              <ReadyUploadSource @getSource="getGoodsImg" @changeOrder="changeOrder" @removeThis="removeImageThis"
                :isMany="true" :manyPath="goodsimgList" :isManyMax="10" :maxSize="1024 * 1024"></ReadyUploadSource>
              <p>建议图片尺寸750px*750px，大小不超过1M，最多可以上传10张图片，其中第一张为主图（有主图视频除外）</p>
            </el-form-item>
            <el-form-item label="商品主图：" class="item" prop="goods_cover_picture">
              <ReadyUploadSource @getSource="val => (goodsForm.goods_cover_picture = val.path)"
                :path="goodsForm.goods_cover_picture" @removeThis="() => (goodsForm.goods_cover_picture = '')">
              </ReadyUploadSource>
              <p>建议图片尺寸750px*750px，大小不超过1M，仅支持1张</p>
            </el-form-item>
            <el-form-item label="主图视频：" class="item">
              <ReadyUploadSource @getSource="getVideoSource" @removeThis="removeVideoThis" :path="goodsForm.goods_video"
                type="video"></ReadyUploadSource>
              <p>建议视频尺寸750px*750px，视频大小，限制50M/个以内，格式支持mp4、3gp、m3u8</p>
            </el-form-item>
          </div>
        </div>
        <div class="price-stock">
          <div class="title">价格库存</div>
          <div class="content">
            <div class="item set-price-stock">
              <span
                style="width: 160px; color: #606266; margin: 12px 0 0 0; padding-right: 12px; box-sizing: border-box; text-align: right">价格库存：</span>
              <div class="set-list">
                <div class="set-list-item">
                  <el-form-item label="价格（元）" prop="goods_price">
                    <el-input type="number" :min="0" v-model="goodsForm.goods_price"></el-input>
                  </el-form-item>
                </div>
                <div class="set-list-item">
                  <el-form-item label="库存" :prop="!goodsForm.is_spec && !goodsForm.is_zone_price ? 'goods_stock' : ''">
                    <el-input type="number" :min="0" v-model="goodsForm.goods_stock"
                      :disabled="goodsForm.is_spec || goodsForm.is_zone_price"></el-input>
                  </el-form-item>
                </div>
                <div class="set-list-item">
                  <el-form-item label="重量（g）">
                    <el-input type="number" :min="0" v-model="goodsForm.goods_kg"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <el-form-item label="商品原价：" class="item" prop="goods_prime_cost">
              <el-input type="number" :min="0" v-model="goodsForm.goods_prime_cost" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="商品佣金：" class="item">
              <el-input type="number" :min="0" v-model="goodsForm.goods_commission" placeholder="请输入内容"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="other-info">
          <div class="title">其他信息</div>
          <div class="content">
            <el-form-item v-if="is_edit" label="运费设置：" class="item set-freight">
              <el-radio-group v-model="goodsForm.freight_type" @change="setfreightChange">
                <div class="set-unified-freight">
                  <el-radio :label="0">统一运费</el-radio>
                  <el-input type="number" :min="0" placeholder="请输入内容" @change="goodsForm.freight_type = 0"
                    v-model="goodsForm.freight_price">
                    <template slot="append">元</template>
                  </el-input>
                  <p style="font-size: 14px; margin-left: 15px">填写“0”或不填则代表免费包邮</p>
                </div>
                <div class="set-template">
                  <el-radio @change="goodsForm.freight_price = ''" :label="1">运费模板</el-radio>
                  <el-select v-model="goodsForm.freight_id" @change="goodsForm.freight_type = 1" filterable
                    placeholder="请选择">
                    <el-option v-for="item in freightList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                  <router-link to="/set/freight" style="margin-left: 15px">
                    <el-button type="text">没有模板，去添加模板</el-button>
                  </router-link>
                </div>
                <div class="set-template default-freight">
                  <el-radio :label="2">默认模板</el-radio>
                  <p style="font-size: 14px">无默认模板则默认为运费为0</p>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否推荐：">
              <el-radio v-model="goodsForm.is_recommend" :label="0">否</el-radio>
              <el-radio v-model="goodsForm.is_recommend" :label="1">是</el-radio>
            </el-form-item>
            <el-form-item label="是否上架：">
              <el-radio v-model="goodsForm.is_putaway" :label="0">下架</el-radio>
              <el-radio v-model="goodsForm.is_putaway" :label="1">上架</el-radio>
            </el-form-item>
            <el-form-item label="售卖时间：" class="up-frame-time">
              <div class="set-up-fram">
                <el-date-picker v-model="sell_time" @change="val => (val ? (sell_time = val.getTime()) : '')"
                  type="datetime" placeholder="请选择上架售卖时间"></el-date-picker>
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="goods-spec" v-show="activeName == 2">
        <div class="content">
          <el-form-item label="是否使用规格：" class="enable-spec">
            <el-switch v-model="goodsForm.is_spec" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="添加规格：" class="add-spec" v-if="goodsForm.is_spec">
            <el-button @click="addSpecGroup" icon="el-icon-plus">添加规格</el-button>
            <p>最多支持添加3种规格</p>
          </el-form-item>
          <el-form-item label="" class="item spec-group" v-if="specGroup.length && goodsForm.is_spec">
            <div class="spec-group-box">
              <div class="spec-group-item" v-for="(item, i) in specGroup" :key="i">
                <div class="spec-name">
                  <p>规格名称</p>
                  <el-input v-model="item.name" @change="handleSpecGroupChange(2, i)" placeholder="请输入规格名称"></el-input>
                </div>
                <div class="spec-detail">
                  <p>规格详情</p>
                  <div class="spec-detail-item">
                    <div class="el-input-box" v-for="(detail, y) in item.detail" :key="y">
                      <el-input v-model="detail.detail" @change="handleSpecGroupChange(4, i, y, $event)"
                        placeholder="请输入规格详情"></el-input>
                      <i class="el-icon-circle-close" @click="removeSpecDetail(i, y)"></i>
                    </div>
                    <p @click="addSpecDetail(i)">+添加详情</p>
                  </div>
                </div>
                <div class="close" @click="removeSpecGroup(i)">
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
            </div>
            <div v-show="spec_group_tips" class="spec-group-tips" style="color: #f56c6c">请将规格信息填写完整</div>
          </el-form-item>
          <el-form-item class="item spec-queue" v-if="specList.length && goodsForm.is_spec">
            <div class="spec-queue-box">
              <table border="1">
                <thead>
                  <tr>
                    <th v-for="(item, index) in specName" :key="index">{{ item }}</th>
                    <th>* 价格</th>
                    <th>* 库存</th>
                    <th>重量（g）</th>
                    <th>商品原价</th>
                    <th>成本价（元）</th>
                    <th>* 商品图片（800*800px）</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in specList" :key="index">
                    <td v-for="(name, i) in item.name" :key="i">{{ name }}</td>
                    <td>
                      <el-input type="number" :min="0" v-model="item.form.price"></el-input>
                    </td>
                    <td>
                      <el-input type="number" :min="0" v-model="item.form.stock"></el-input>
                    </td>
                    <td>
                      <el-input type="number" :min="0" v-model="item.form.kg"></el-input>
                    </td>
                    <td>
                      <el-input type="number" :min="0" v-model="item.form.prime_cost"></el-input>
                    </td>
                    <td class="coding_td">
                      <el-input v-model="item.form.commission"></el-input>
                    </td>
                    <td>
                      <div class="add-img-box">
                        <ReadyUploadSource :showStyle="{
                          width: '80px',
                          height: '80px',
                        }" type="image" :path="item.form.picture" @removeThis="() => (item.form.picture = '')"
                          @getSource="val => (item.form.picture = val.path)"></ReadyUploadSource>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <el-form>
              <el-form-item class="spec-batch-input" label="批量输入：">
                <el-input v-model="spec_batch.price" :min="0" type="number" placeholder="价格（元）"></el-input>
                <el-input v-model="spec_batch.stock" :min="0" type="number" placeholder="库存"></el-input>
                <el-input v-model="spec_batch.kg" :min="0" type="number" placeholder="重量（g）"></el-input>
                <el-input v-model="spec_batch.prime_cost" :min="0" type="number" placeholder="商品原价"></el-input>
                <el-input v-model="spec_batch.commission" placeholder="成本价（元）"></el-input>
                <el-button type="primary" @click="confirmBatchSpec">确定</el-button>
              </el-form-item>
            </el-form>
          </el-form-item>
        </div>
      </div>
      <div class="goods-detail" v-show="activeName == 3">
        <div class="content">
          <el-form-item label="商品详情：" prop="goods_details">
            <RichText :richTxt="goodsForm.goods_details" @soninfo="val => (goodsForm.goods_details = val)"></RichText>
          </el-form-item>
        </div>
      </div>
      <div class="activity" v-show="activeName == 4">
        <div class="content">
          <el-form-item class="item" label="购买商品送积分：">
            <el-switch v-model="goodsForm.is_buy_integral" :active-value="1" :inactive-value="0"></el-switch>
            <span>开启设置后，用户购买此商品，无论任意价格，都可以获得设置的积分</span>
          </el-form-item>
          <el-form-item class="item" label="赠送积分：" v-if="goodsForm.is_buy_integral"
            :prop="goodsForm.is_buy_integral ? 'integral_amount' : ''">
            <el-input v-model="goodsForm.integral_amount"></el-input>
          </el-form-item>
        </div>
      </div>
      <Preservation v-if="is_edit" @preservation="goodsSubmit">
        <router-link :to="is_edit ? '/gateway/goods/goodsList' : '/store/goodsExamine'" slot="return" v-if="id"
          style="margin-right: 10px">
          <el-button>返回</el-button>
        </router-link>
      </Preservation>
    </el-form>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
  components: {
    ReadyUploadSource,
    RichText,
    Preservation,
  },
  data () {
    return {
      //是否可以编辑
      is_edit: !0,
      id: '',
      frameTime: '',
      activeName: '1',
      init1: !0,
      init2: !0,
      init3: !0,
      freightList: [],
      spec_group_tips: !1,
      currentSpecCityIndex: 0,
      rules: {
        classify_ids: {
          required: true,
          message: '请选择商品分类',
          trigger: 'blur',
        },
        goods_name: {
          required: true,
          message: '请输入商品名称',
          trigger: 'blur',
        },
        goods_price: {
          required: true,
          message: '请输入商品价格',
          trigger: 'blur',
        },
        goods_stock: {
          required: true,
          message: '请输入商品库存',
          trigger: 'blur',
        },
        goods_prime_cost: {
          required: true,
          message: '请输入商品原价',
          trigger: 'blur',
        },
        goods_picture: {
          type: 'array',
          required: true,
          message: '请添加商品图片',
          trigger: 'change',
        },
        goods_cover_picture: {
          required: true,
          message: '请添加商品主图',
          trigger: 'change',
        },
        goods_details: {
          required: true,
          message: '请输入商品详情',
          trigger: 'blur',
        },
        price: {
          required: true,
          message: '请输入区域价格',
          trigger: 'blur',
        },
        stock: {
          required: true,
          message: '请输入区域库存',
          trigger: 'blur',
        },
        integral_amount: {
          required: true,
          message: '请输入赠送积分',
          trigger: 'blur',
        },
      },
      sell_time: '',
      specGroup: [], //规格组
      specList: [], //规格列表
      specName: [],
      goodsimgList: [],
      isShowMark: !1, //是否设置角标
      //复用参数
      specList_form: {
        price: '',
        stock: '',
        kg: '',
        prime_cost: '',
        commission: '',
        picture: '',
      },
      goodsForm: {
        classify_id: '', //商品分类ID
        classify_ids: '',
        sort: '', //排序
        goods_name: '', //商品名称
        goods_subtitle: '', //商品副标题
        goods_tag: '', //搜索关键词
        virtual_sales: '', //虚拟销量
        goods_picture: [], //商品图片
        goods_cover_picture: '', //商品主图
        is_mark: 1, //显示角标位置  0无，1左下角，2底部，3右下角，4整图
        mark: '', //角标图片
        goods_video: '', //视频地址
        goods_details: '', //商品详情
        is_recommend: 0, //是否推荐，0否，1是
        is_putaway: 1, //是否上架，0下架，1上架
        sell_time: '', //售卖时间
        freight_type: 2, //运费类型，0运费，1运费模板 2默认模板
        freight_id: '', //运费，运费模板ID
        freight_price: '', //运费价格
        goods_price: '', //商品价格
        goods_prime_cost: '', //商品原价
        goods_commission: '', //商品佣金
        goods_stock: '', //商品库存
        goods_kg: '', //商品重量
        is_spec: 0, //是否多规格
        spec: [], //多规格
        goods_spec: [], //规格信息
        is_buy_integral: 0, //是否开启送积分
        integral_amount: '',
      },
      spec_batch: {
        price: '',
        stock: '',
        kg: '',
        prime_cost: '',
        commission: '',
      },
    };
  },
  watch: {
    goodsForm: {
      handler (val) {
        if (this.is_edit) {
          this.$store.state.utils.is_update = 1;
        }
      },
      deep: true,
    },
  },
  computed: {
    goodsClass () {
      return this.$store.getters.goodsTypeList;
    },
  },
  created () {
    let id = this.$route.query.id;
    if (id) {
      let utils = this.$store.state.utils;
      utils.page = Number(this.$route.query.page);
      utils.rows = Number(this.$route.query.rows);
      utils.is_record = !0;
      this.id = id;
      this.getGoodsDetail();
    }
    if (this.$route.query.is_other) {
      this.is_edit = !1;
      this.id = this.$route.query.id;
      this.getGoodsDetail();
    }
    if (this.is_edit) {
      this.$store.dispatch('goods/getTypeList', { industryName:'/Gateway' });
      this.getfreightList();
    }
  },
  methods: {
    //添加当前规格区域
    addCurrentSpecCity (index) {
      this.currentSpecCityIndex = index;
      this.showCity = !0;
    },
    //删除规格组
    removeSpecGroup (i) {
      this.$confirm('确认要删除规格信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.specGroup.splice(i, 1);
        this.specList = [];
        this.handleGoupAdd(this.specGroup.length);
      });
    },
    //获取视频地址
    getVideoSource (val) {
      this.goodsForm.goods_video = val.path;
    },
    //
    removeVideoThis () {
      this.goodsForm.goods_video = '';
    },
    // 删除单张图片
    removeImageThis (index) {
      // 展示用的数组
      this.goodsimgList.splice(index, 1);
      // 调接口时使用的数组
      this.goodsForm.goods_picture.splice(index, 1);
    },
    closeAddStore () {
      this.showAddStore = !1;
    },
    //获取商品图片
    getGoodsImg (val) {
      this.goodsimgList.push(...val);
      let arr = [];
      for (let i in this.goodsimgList) arr.push(this.goodsimgList[i].path);
      this.goodsForm.goods_picture = arr;
    },
    // 拖动重新赋值数据
    changeOrder (list) {
      this.goodsimgList = list;
      let arr = [];
      for (let i in this.goodsimgList) arr.push(this.goodsimgList[i].path);
      this.goodsForm.goods_picture = arr;
    },
    setfreightChange () {
      this.goodsForm.freight_price = '';
      this.goodsForm.freight_id = '';
    },
    handleClassChange (val) {
      let arr = [];
      for (let i in val) {
        for (let y in val[i]) {
          arr.push(val[i][y]);
        }
      }
      arr = [...new Set(arr)];
      this.goodsForm.classify_id = arr.join();
    },
    //获取运费模板
    getfreightList () {
      this.$axios.post(this.$api.set.freightList, { rows: 10000 }).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.freightList = list;
        }
      });
    },
    //获取商品详情
    getGoodsDetail () {
      this.$axios.post(this.$api.goods.goodsInfo, { id: this.id }).then(res => {
        if (res.code == 0) {
          let spec = res.result.spec;
          let result = res.result;
          let spec_sku = res.result.spec_sku;
          let specList = this.specList;
          for (let i in spec) {
            let obj = {
              name: '',
              detail: [],
            };
            obj.name = spec[i].spec_name;
            for (let y in spec[i].spec_value) {
              obj.detail.push({
                detail: spec[i].spec_value[y].spec_value,
              });
            }
            this.specGroup.push(obj);
          }
          for (let i in this.specGroup) this.specName.push(this.specGroup[i].name);
          for (let i in spec_sku) {
            let name = [];
            for (let y in spec_sku[i].sku) {
              name.push(spec_sku[i].sku[y].spec_value);
            }
            specList.push({
              form: spec_sku[i],
              name: name,
            });
          }
          result.goods_picture = JSON.parse(result.goods_picture);
          result.classify_ids = JSON.parse(result.classify_ids);
          for (let i in result.goods_picture) {
            this.goodsimgList.push({
              path: result.goods_picture[i],
              isSelect: !1,
            });
          }
          if (result.freight_id == 0) result.freight_id = '';
          if (result.freight_type == 2) {
            result.freight_id = '';
            result.freight_price = '';
          }
          if (result.sell_time > 0) this.sell_time = new Date(result.sell_time * 1000);
          if (this.specGroup > 1) this.handleGoupAdd(this.specGroup.length, 3, 1);
          this.init1 = !1;
          this.init2 = !1;
          this.init3 = !1;
          this.goodsForm = result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //商品提交
    async goodsSubmit () {
      try {
        let that = this;
        let specList = [...this.specList];
        let specGroup = this.specGroup;
        let goodsForm = _.cloneDeep(this.goodsForm);
        let spec = [];
        let goods_spec = [];
        let verifyForm = !1;
        this.$refs.form.validate((valid, val) => {
          for (let i in val) {
            return this.$message.warning(val[i][0].message);
          }
          verifyForm = valid;
        });
        if (!verifyForm) return;
        if (goodsForm.is_spec) {
          this.rulesGroup();
          if (!this.specGroup.length) throw '请添加规格';
          if (this.spec_group_tips) throw '请填写完整的规格信息';
          //多规格数据处理
          for (let i in specGroup) {
            let obj = {
              spec_name: '',
              spec_value: [],
            };
            obj.spec_name = specGroup[i].name;
            for (let y in specGroup[i].detail)
              obj.spec_value.push({
                spec_value: specGroup[i].detail[y].detail,
              });
            spec.push(obj);
          }
          //添加规格参数
          for (let i in specList) goods_spec.push(specList[i].form);
          //添加规格详情
          for (let i in specList) {
            let arr = [];
            for (let y in specList[i].name) {
              let obj = {};
              obj.spec_value = specList[i].name[y];
              arr.push(obj);
            }
            goods_spec[i].sku = arr;
          }
          //检查规格参数
          for (let i in goods_spec) {
            for (let y in goods_spec[i]) {
              if (y == 'price' || y == 'stock') {
                if (goods_spec[i][y] == '') throw '请填写完整的规格信息';
              }
              if (y == 'picture') {
                if (goods_spec[i][y] == '') {
                  goods_spec[i][y] = goodsForm.goods_picture[0];
                }
              }
            }
          }
          goodsForm.spec = spec;
          goodsForm.goods_spec = goods_spec;
        }
        goodsForm.spec_sku = goodsForm.goods_spec;
        goodsForm.sell_time = this.sell_time / 1000;
        delete goodsForm.goods_spec;
        let url = this.id ? this.$api.goods.editGoods :  this.$api.goods.addGoods;
        let addRequest = function () {
          that.$axios.post(url, goodsForm).then(res => {
            if (res.code == 0) {
              that.$message.success(`${that.id ? '商品编辑成功' : '商品添加成功'}`);
              that.$router.push('/goods/goodsList');
            } else {
              that.$message.error(res.msg);
            }
          });
        };
        if (goodsForm.freight_type == 2) {
          let isSelect = await this.$axios.post( this.$api.goods.isSelect);
          if (isSelect.code == 0) {
            if (isSelect.result.is_exist) {
              addRequest();
            } else {
              this.goodsForm.freight_type = 0;
              this.goodsForm.freight_price = 0;
              addRequest();
            }
          } else {
            throw isSelect.msg;
          }
        } else {
          addRequest();
        }
      } catch (e) {
        this.$message.warning(e);
      }
    },
    //批量输入
    confirmBatchSpec () {
      let spec_batch = this.spec_batch;
      let specList = this.specList;
      for (let i in spec_batch) {
        if (spec_batch[i]) {
          for (let y in specList) specList[y].form[i] = spec_batch[i];
        }
      }
      for (let i in spec_batch) spec_batch[i] = '';
    },
    //添加规格组
    addSpecGroup () {
      if (this.specGroup.length == 3) return this.$message.warning('最多只能添加3组规格');
      let obj = {
        name: '',
        detail: [
          {
            detail: '',
          },
          {
            detail: '',
          },
        ],
      };
      this.specGroup.push(obj);
      if (this.specGroup.length > 1) this.specList = [];
    },
    //添加规格详情
    addSpecDetail (i) {
      this.specGroup[i].detail.push({
        detail: '',
      });
      this.handleSpecGroupChange(3);
    },
    //删除规格详情
    removeSpecDetail (i, y) {
      this.$confirm('确认要删除规格信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.specGroup[i].detail.splice(y, 1);
        this.handleSpecGroupChange(5, i, y);
      });
    },
    //处理规格复用数据的添加
    handleGoupAdd (num, actions, type) {
      let specList = this.specList;
      let specGroup = this.specGroup;
      let length = specGroup.length;
      let [arr1, arr2, arr3, list] = [[], [], [], []];
      this.specName = [];
      if (num == 1) {
        arr1 = specGroup[0].detail;
        for (let i in arr1) {
          let obj = {
            name: [],
            form: Object.assign({}, this.specList_form),
          };
          obj.name.push(arr1[i].detail);
          specList.push(obj);
        }
      }
      if (num == 2) {
        arr1 = specGroup[0].detail;
        arr2 = specGroup[1].detail;
        for (let i in arr1) {
          for (let y in arr2) {
            let obj = {
              name: [],
              i: i,
              y: y,
              form: Object.assign({}, this.specList_form),
            };
            obj.name.push(arr1[i].detail);
            obj.name.push(arr2[y].detail);
            list.push(obj);
          }
        }
        if (actions == 3) {
          if (type) {
            list.map((item, index) => {
              specList[index].i = item.i;
              specList[index].y = item.y;
            });
          }
          for (let i in list) {
            for (let y in specList) {
              //添加规格值时根据添加后的数据下标对比原先数据的下标把原先的from赋值给新的数据
              if (specList[y].i + specList[y].y == list[i].i + list[i].y) list[i].form = specList[y].form;
            }
          }
          this.specList = list;
        } else {
          this.specList.push(...list);
        }
      }
      if (num == 3) {
        arr1 = specGroup[0].detail;
        arr2 = specGroup[1].detail;
        arr3 = specGroup[2].detail;
        for (let i in arr1) {
          for (let y in arr2) {
            for (let k in arr3) {
              let obj = {
                name: [],
                i: i,
                y: y,
                k: k,
                form: Object.assign({}, this.specList_form),
              };
              obj.name.push(arr1[i].detail);
              obj.name.push(arr2[y].detail);
              obj.name.push(arr3[k].detail);
              list.push(obj);
            }
          }
        }
        if (actions == 3) {
          //添加规格值时根据添加后的数据下标对比原先数据的下标把原先的from赋值给新的数据
          if (type) {
            list.map((item, index) => {
              specList[index].i = item.i;
              specList[index].y = item.y;
              specList[index].k = item.k;
            });
          }
          for (let i in list) {
            for (let y in specList) {
              if (specList[y].i + specList[y].y + specList[y].k == list[i].i + list[i].y + list[i].k) list[i].form = specList[y].form;
            }
          }
          this.specList = list;
        } else {
          this.specList.push(...list);
        }
      }
      //取规格名称
      for (let i = 0; i < length; i++) {
        this.specName.push(specGroup[i].name);
      }
      this.rulesGroup();
    },
    //规格信息验证
    rulesGroup () {
      let specGroup = this.specGroup;
      for (let i in specGroup) {
        if (specGroup[i].name) {
          this.spec_group_tips = !1;
        } else {
          this.spec_group_tips = !0;
          return;
        }
        if (specGroup[i].detail.length) {
          for (let y in specGroup[i].detail) {
            if (specGroup[i].detail[y].detail) {
              this.spec_group_tips = !1;
            } else {
              this.spec_group_tips = !0;
              return;
            }
          }
        } else {
          this.spec_group_tips = !0;
          return;
        }
      }
    },
    /**
     * 规格组输入的变化
     * @param actions 操作方式 2.输入规格名称 3.添加规格值 4.输入规格值 5.删除规格值
     * @param index 规格组specGroup下标
     * @param indexs 规格组specGroup —> detail下标
     * @param val 规格值
     */
    handleSpecGroupChange (actions, index, indexs, val) {
      let specList = this.specList;
      let specGroup = this.specGroup;
      let length = specGroup.length;
      let arr1 = specGroup[0] ? specGroup[0].detail : null;
      let arr2 = specGroup[1] ? specGroup[1].detail : null;
      let arr3 = specGroup[2] ? specGroup[2].detail : null;
      switch (actions) {
        case 2: //输入规格名称
          //处理一组规格
          if (length == 1) {
            //判断是否第一次添加
            if (this.init1) {
              this.init1 = !1;
              this.handleGoupAdd(1);
            } else {
              //取规格名称
              this.specName = [];
              for (let i = 0; i < length; i++) {
                this.specName.push(specGroup[i].name);
              }
            }
          } else {
            if (this.init2) {
              this.init2 = !1;
              this.handleGoupAdd(length);
            } else {
              //判断每个规格组有没有规格值
              let is_val = !0;
              for (let i in specGroup) {
                if (!specGroup[i].detail.length) {
                  is_val = !1;
                  break;
                }
              }
              this.specName = [];
              if (is_val) {
                //有规格值得全部修改
                for (let i = 0; i < length; i++) {
                  this.specName.push(specGroup[i].name);
                }
              } else {
                //没有规格值的不修改
                for (let i in specGroup) {
                  if (specGroup[i].detail.length) {
                    this.specName.push(specGroup[i].name);
                  }
                }
              }
            }
          }
          break;
        case 3:
          if (length == 1) {
            if (!this.init1) {
              if (specList.length) {
                specList.push({
                  name: [''],
                  form: Object.assign({}, this.specList_form),
                });
              }
            }
          } else if (length == 2) {
            this.init1 = !1;
            this.init2 = !1;
            this.handleGoupAdd(2, 3);
          } else if (length == 3) {
            this.init1 = !1;
            this.init3 = !1;
            this.handleGoupAdd(3, 3);
          }
          break;
        case 4:
          //处理修改规格值
          if (length == 1) {
            //修改1组
            if (!specList.length) {
              this.init1 = !1;
              this.handleGoupAdd(1);
            }
            specList[indexs].name = [val];
          } else if (length == 2) {
            //修改2组
            let nameList = [];
            for (let i in arr1) {
              if (arr2.length) {
                for (let y in arr2) {
                  let obj = { name: [] };
                  obj.name.push(arr1[i].detail);
                  obj.name.push(arr2[y].detail);
                  nameList.push(obj);
                }
              } else {
                let obj = { name: [] };
                obj.name.push(arr1[i].detail);
                nameList.push(obj);
              }
            }
            if (!specList.length) {
              this.init2 = !1;
              this.handleGoupAdd(2);
            }
            if (specList.length) {
              for (let i in nameList) specList[i].name = nameList[i].name;
            }
          } else if (length == 3) {
            //修改3组
            let nameList = [];
            for (let i in arr1) {
              for (let y in arr2) {
                if (arr3.length) {
                  for (let k in arr3) {
                    let obj = { name: [] };
                    obj.name.push(arr1[i].detail);
                    obj.name.push(arr2[y].detail);
                    obj.name.push(arr3[k].detail);
                    nameList.push(obj);
                  }
                } else {
                  let obj = { name: [] };
                  obj.name.push(arr1[i].detail);
                  obj.name.push(arr2[y].detail);
                  nameList.push(obj);
                }
              }
            }
            if (!specList.length) {
              this.init3 = !1;
              this.handleGoupAdd(3);
            }
            for (let i in nameList) {
              specList[i].name = nameList[i].name;
            }
          }
          break;
        case 5:
          var i_y = [];
          //处理删除规格值
          if (length == 1) {
            //删除1组
            this.specList.splice(indexs, 1);
          } else if (length == 2) {
            //删除2组
            if (index == 0) {
              let detail = specGroup[index].detail;
              //不是最后一组规格，如果删除完了就清除表单
              if (!detail.length) {
                this.specList = [];
                this.spec_group_tips = !0;
                return;
              }
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].i == indexs) specList.splice(k--, 1);
              }
              //每次删除后重新更新下标
              if (arr1.length) {
                for (let i in arr1) {
                  if (arr2.length) {
                    for (let y in arr2) {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  } else {
                    let obj = { i: i };
                    i_y.push(obj);
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  if (arr2.length) specList[i].y = i_y[i].y;
                }
              }
            } else if (index == 1) {
              let detail = specGroup[index].detail;
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].y == indexs) {
                  if (detail.length == 0) {
                    ////删除规格值时如果当前规格组的值是最后一个，不再对specList整条数据的删除而是删除specList[k].name进行删除
                    specList[k].name.splice(1, 1);
                  } else {
                    specList.splice(k--, 1);
                  }
                }
              }
              //每次删除后重新更新下标
              if (detail.length) {
                for (let i in arr1) {
                  if (arr2.length) {
                    for (let y in arr2) {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  specList[i].y = i_y[i].y;
                }
              }
              //删除规格值时如果当前规格组的值被删完了，表格的规格名称也进行对应删除
              if (!detail.length) this.specName.splice(1, 1);
            }
          } else if (length == 3) {
            //删除3组
            if (index == 0) {
              let detail = specGroup[index].detail;
              //不是最后一组规格，如果删除完了就清除表单
              if (!detail.length) {
                this.specList = [];
                this.spec_group_tips = !0;
                return;
              }
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].i == indexs) specList.splice(k--, 1);
              }
              //每次删除后重新更新下标
              if (arr1.length) {
                for (let i in arr1) {
                  for (let y in arr2) {
                    if (arr3.length) {
                      for (let k in arr3) {
                        let obj = { i: i, y: y, k: k };
                        i_y.push(obj);
                      }
                    } else {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  if (arr2.length) specList[i].y = i_y[i].y;
                }
              }
            } else if (index == 1) {
              let detail = specGroup[index].detail;
              //不是最后一组规格，如果删除完了就清除表单
              if (!detail.length) {
                this.specList = [];
                this.spec_group_tips = !0;
                return;
              }
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].y == indexs) specList.splice(k--, 1);
              }
              //每次删除后重新更新下标
              if (arr2.length) {
                for (let i in arr1) {
                  for (let y in arr2) {
                    if (arr3.length) {
                      for (let k in arr3) {
                        let obj = { i: i, y: y, k: k };
                        i_y.push(obj);
                      }
                    } else {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  specList[i].y = i_y[i].y;
                  if (arr3.length) specList[i].k = i_y[i].k;
                }
              }
              if (!specGroup[index].detail.length) this.specName.splice(1, 1);
            } else {
              let detail = specGroup[index].detail;
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].k == indexs) {
                  if (detail.length == 0) {
                    specList[k].name.splice(2, 1);
                  } else {
                    specList.splice(k--, 1);
                  }
                }
              }
              //每次删除后重新更新下标
              if (arr3.length) {
                for (let i in arr1) {
                  for (let y in arr2) {
                    if (arr3.length) {
                      for (let k in arr3) {
                        let obj = { i: i, y: y, k: k };
                        i_y.push(obj);
                      }
                    } else {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  specList[i].y = i_y[i].y;
                  if (arr3.length) specList[i].k = i_y[i].k;
                }
              }
              if (!specGroup[index].detail.length) this.specName.splice(2, 1);
            }
          }
          break;
      }
      //编辑时如果规格有更改就删除id
      if (this.id) specList.map(item => delete item.form.id);
      this.rulesGroup();
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  position: relative;
  padding: 0;
  height: 100%;

  .tabs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 20px 0 20px;
    z-index: 1000;
    background: #fff;
  }

  .form {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-top: 60px;
    padding: 80px 20px 60px 20px;
    box-sizing: border-box;
  }

  .goods-submit {
    padding-left: 160px;
    margin: 20px 0;
  }

  .goods-detail .item {
    align-items: flex-start !important;
  }

  .basic-info,
  .price-stock,
  .other-info,
  .goods-spec,
  .goods-detail,
  .jd,
  .activity {
    .title {
      line-height: 35px;
      border-bottom: 1px solid #e4e7ed;
    }

    .content {
      padding: 30px 0 0 0;

      .item {
        span {
          color: #999;
          padding-left: 20px;
        }

        p {
          color: #999;
        }

        .el-select,
        .el-input,
        .el-cascader,
        .el-textarea {
          width: 400px;
        }

        /deep/ .el-textarea__inner {
          min-height: 40px !important;
          padding: 8.5px 15px !important;
        }
      }

      .text {
        display: flex;
      }

      .area-price {
        table {
          margin-bottom: 15px;
          border: 1px solid #dcdfe6;

          thead {
            th {
              padding: 0 10px;
            }
          }

          tbody {
            td {
              text-align: center;
              padding: 20px;
              position: relative;

              span {
                line-height: 20px;
              }

              i {
                position: absolute;
                top: 5px;
                right: 5px;
                font-size: 20px;
                cursor: pointer;
              }

              .el-input {
                width: 200px;
              }
            }
          }
        }
      }

      .spec-queue {
        flex-direction: column;
        align-items: flex-start;

        .spec-queue-box {
          display: flex;

          span {
            width: 160px;
            margin-right: 10px;
          }

          table {
            border: 1px solid #dcdfe6;

            thead {
              th {
                padding: 10px;
              }
            }

            tbody {
              td {
                text-align: center;
                padding: 0 10px;

                .el-input {
                  width: 100px;
                }
              }

              td:last-child {
                padding: 10px 0;

                .add-img-box {
                  width: 80px;
                  margin: 0 auto;
                }
              }

              .coding_td .el-input {
                width: 200px;
              }
            }
          }
        }

        .spec-batch-input {
          width: 100%;
          display: flex;
          margin-top: 10px;

          .el-input {
            width: 120px;
            margin: 0;
            margin-right: 10px;
          }
        }
      }

      .set-price-stock {
        align-items: flex-start;
        display: flex;
      }

      .set-price-stock .set-list {
        display: flex;
        align-items: center;
        text-align: left;

        .set-list-item {
          margin-right: 20px;

          /deep/ label {
            width: auto !important;
          }

          /deep/ .el-form-item__content {
            margin: 0 !important;
          }

          p {
            padding: 0;
            margin-bottom: 15px;
            color: #333;
          }

          .el-input {
            width: 200px;
          }
        }
      }

      .set-freight {
        align-items: flex-start;
        display: inline-block;

        span {
          padding-top: 5px;
        }

        .set-unified-freight {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        }

        .set-template {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 15px;

          &:last-child {
            margin: 0;
          }
        }
      }

      .up-frame-time .set-up-fram {
        display: flex;
        align-items: center;

        .el-date-picker {
          width: 250px;
        }
      }

      .spec-group {
        display: flex;

        .spec-group-box {
          border: 1px solid #dcdfe6;
        }

        .spec-group-item {
          padding: 20px 20px 0 20px;
          border-bottom: 1px solid #dcdfe6;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .close {
            position: absolute;
            z-index: 10;
            top: 10px;
            right: 10px;

            i {
              font-size: 20px;
            }
          }

          &:last-child {
            border: 0;
          }

          p {
            margin-right: 20px;
            line-height: 40px;
          }

          .spec-name {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .el-input {
              width: 220px;
            }
          }

          .spec-detail {
            display: flex;
            align-items: flex-start;

            .spec-detail-item {
              display: flex;
              width: 850px;
              align-items: flex-start;
              justify-content: flex-start;
              flex-wrap: wrap;

              .el-input-box {
                position: relative;

                i {
                  position: absolute;
                  top: -5px;
                  right: 5px;
                  font-size: 17px;
                  cursor: pointer;
                }
              }

              .el-input {
                width: 220px;
                margin-right: 10px;
                margin-bottom: 20px;
              }

              p {
                color: #409eff;
                padding: 0;
                line-height: 40px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
</style>
